<template>
	<!-- <router-link :to="funds_route + '/' + card.slug"> -->
	<div class="bg-white text-pine">
		<router-link :to="'/' + route_funds + '/' + card.slug">
			<div class="w-85% mx-auto my-0 pb-12 h-full mx640:pb-8">
				<div class="pt-65 flex items-center row-span-1 pb-100 mx640:py-8">
					<img
						v-if="card.image"
						class="mx-auto my-0 mx900:max-w-3/4"
						:src="card.image.sm"
						alt=""
					/>
				</div>
				<div class="row-span-1 self-center pb-70 mx640:pb-5">
					<div class="text-40 font-national mx1080:text-4 mx720:text-6">
						{{ card.title }}
					</div>
					<div
						class="text-24 font-tiempos_r leading-9 mx1080:text-2 mx720:text-4"
					>
						{{ card.description }}
					</div>
				</div>

				<div
					class="self-center row-span-1 mx1080:text-2 mx720:text-4 pb-16 mx640:pb-4"
					v-if="card.specs"
				>
					<div class="border-b border-crab"></div>
					<div
						v-for="(item, index) in card.specs"
						:key="index"
						class="border-b border-crab py-4"
					>
						{{ item.spec }}
					</div>
				</div>

				<div class="text-center anime-hover">
					<router-link
						class="font-inter text-xl mx640:4vw"
						:to="'/' + route_funds + '/' + card.slug"
					>
						{{ currentLanguage === "en" ? "Read more" : "Lees meer" }}
					</router-link>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "FundsCard",
		data() {
			return {
				currentLanguage: globals.language,
				route_funds: globals.navigation["funds"].slug,
			};
		},
		props: ["card"],
	};
</script>

<style></style>

<template>
	<div>
		<div v-if="show === 'yes'">
			<div class="bg-shea">
				<div class="mode-center pt-170 anime-hover">
					<router-link
						class="text-xl font-inter text-pine py-2 mx640:text-3.5"
						:to="'/' + route_funds"
						@click.native.prevent="navigate_back"
						>{{ btn_funds_back }}</router-link
					>
					<div
						class="text-pine grid grid-cols-2 gap-8 pt-20 pb-100 mx640:grid-cols-1 items-center"
					>
						<div v-if="singleFundData.image" class="">
							<div class="circular-logo mx640:mx-auto mn640:ml-6">
								<img
									class="mx1080:w-80%"
									:src="singleFundData.image.sm"
									alt=""
								/>
							</div>
						</div>
						<div class="font-tiempos_r">
							<h1 class="h1-text pb-10 mx640:text-6 mx1080:text-5">
								{{ singleFundData.title }}
							</h1>
							<div
								v-if="singleFundData.payoff"
								class="text-30 mx1080:text-3 mx640:text-4.5"
							>
								{{ singleFundData.payoff }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mode-center" v-if="singleFundData.specs">
				<div
					class="bg-pine transform -translate-y-12 px-100 py-12 text-white text-22 mx900:px-10 mx640:text-4.5 font-tiempos_r"
				>
					<div
						class="grid grid-cols-2 gap-8 gap-y-3 mx480:grid-cols-1 mx480:gap-0"
					>
						<div class="border-crab border-b "></div>
						<div class="border-crab border-b mx480:hidden"></div>
						<div
							class="border-crab border-b pb-3 mx480:border-t-0"
							v-for="(item, index) in singleFundData.specs"
							:key="index"
						>
							{{ item.spec }}
						</div>
					</div>
				</div>
			</div>

			<div v-for="(item, index) in singleFundData.blocks" :key="index">

				<div class="text-pine" v-if="item.type == 'richtext'">
					<div class="pt-32 w-73% mx-auto my-0 pb-120 mx640:w-90%">
						<div
							v-html="item.richtext"
							class="text-24 w-70% ml-auto font-tiempos_r leading-9 pb-100 mx1080:text-2.5 mx900:w-full mx640:text-4.5"
						></div>
					</div>
				</div>

				<div class="bg-shea" v-if="item.type == 'navigation'">
					<div class="mode-center text-center p-10 anime-hover">
						<div class="inline-block mx720:w-full my-5" v-for="item in sections_navigation" :key="item.key">
							<!--  
							<a :href="'#' + item.link" class="cursor-pointer text-xl font-inter text-pine py-2 mx640:text-3.5 mx-16" @click.prevent.stop="section_nav(item.link)">
							-->
							<a href="#" class="cursor-pointer text-xl font-inter text-pine py-2 mx640:text-3.5 mx-16" @click.prevent.stop="section_nav(item.link)">
								{{item.title}}
							</a>
						</div>
					</div>
				</div>

				<div class="bg-pine" v-if="item.type == 'quote'">
					<div class="quote-section">
						<div
							class="mode-center pt-20 text-white text-30 font-tiempos_r pb-16 leading-48 mx640:text-4.5"
						>
							<div class="w-85% mx640:w-full">
								<div class="pb-12">
									{{ item.quote }}
								</div>
								<div class="">— {{ item.author }}</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="item.type == 'list'" :id="'section_' + item.id">
					<div class="bg-pine" v-if="item.list_style == 'normal'">
						<div class="anime-hover-mandarin mode-center text-white pb-10">
							<div class="pt-120 pb-24 w-1/2 mx640:w-full">
								<h1
									class="font-national text-65 mx640:text-9 leading-70 mb-5"
									v-if="item.title"
								>
									{{ item.title }}
								</h1>
								<h3 class="text-22 font-tiempos_r" v-if="item.description">
									{{ item.description }}
								</h3>
							</div>
							<div
								class="grid grid-cols-8 gap-4 pb-100 font-tiempos_r w-11/12 mx900:w-full"
								v-for="item in item.list"
								:key="item.key"
							>
								<div
									class="col-span-2 col-start-2 text-30 mx1280:text-3.5 mx640:col-span-7 mx640:text-6 leading-tight mx900:col-start-1"
								>
									{{ item.title }}
								</div>
								<div
									class="col-span-4 col-start-5 text-22 mx1280:text-2 mx900:text-2.5 mx640:col-span-8 mx640:pt-10 mx640:text-4"
								>
									<div v-html="item.text" class="mb-6 rich-text"></div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="item.list_style == 'numbered'">
						<div class="mode-center text-pine pb-40">
							<div class="pt-120 pb-24 w-1/2 mx640:w-full">
								<h1
									class="font-national text-65 mx640:text-9 leading-70 mb-5"
									v-if="item.title"
								>
									{{ item.title }}
								</h1>
								<h3 class="text-22 font-tiempos_r" v-if="item.description">
									{{ item.description }}
								</h3>
							</div>
							<StepList
								v-for="(step, index) in item.list"
								:key="index"
								:index="index"
								:step="step"
							/>
						</div>
					</div>
				</div>

				<div class="bg-mandarin" v-if="item.type == 'cta'">
					<div
						class="anime-hover-white mode-cennter py-16 text-center text-pine segment_text"
						v-if="item.text"
						v-html="item.text"
					></div>
				</div>

				<div v-if="item.type == 'carrousel'">
					<div class="carousel__wrapper">
						<PhotoCarousel :obj="item.carrousel" />
					</div>
				</div>

				<div v-if="item.type == 'companies'" :id="'section_' + item.id">
					<PortfolioSection :obj="item" />
				</div>

				<div v-if="item.type == 'organisations'" :id="'section_' + item.id">
					<Companies :obj="item" />
				</div>
			</div>

			<!-- 
				!!!!
				Ja zeg, we kunnen wel alles gaan verzinnen zonder het eerst met de developers te overleggen, maar hé, wat verwacht je dan? 
				sorry Amir, hidden
				!!!!
			-->
			<div class="bg-shea hidden">
				<div class="mode-center">
					<div
						class="flex justify-between w-1/2 mx-auto py-10 mx1080:w-80% mx720:w-full mx640:flex-wrap"
					>
						<div class="anime-hover mx640:mb-5">
							<router-link class="font-inter text-xl mx640:4vw" to="/">
								Wat we bieden
							</router-link>
						</div>
						<div class="anime-hover mx640:mb-5">
							<router-link class="font-inter text-xl mx640:4vw" to="/">
								Investeringscriteria
							</router-link>
						</div>
						<div class="anime-hover mx640:mb-5">
							<router-link class="font-inter text-xl mx640:4vw" to="/">
								Wat we bieden
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page_404 v-if="show === '404'" />
	</div>
</template>

<script>
	import gsap from "gsap";
	import { ScrollToPlugin } from "gsap/ScrollToPlugin";
	gsap.registerPlugin(ScrollToPlugin);

	import data from "@/data.json";
	import StepList from "@/components/ui/StepList.vue";
	import PhotoCarousel from "@/components/ui/PhotoCarousel.vue";
	import PortfolioSection from "@/components/ui/PortfolioSection.vue";
	import { globals } from "@/modules/globals";
	import page_404 from "@/components/page_404.vue";
	import Companies from "../components/ui/Companies.vue";

	export default {
		name: "FundSingle",
		components: {
			page_404,
			PhotoCarousel,
			StepList,
			Companies,
			PortfolioSection,
		},
		data() {
			return {
				test: [
					{
						title: "SI1",
						link: "https://www.si2fund.com/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/si2.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/si2.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/si2.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/si2.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/si2.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
					{
						title: "Inzet",
						link: "https://inzet.investments/",
						image: {
							file: "https://sig.teemzero.com/shaping_impact/assets/inzet.png",
							xs:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=100&h=100&f=contain&q=100",
							sm:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=300&h=300&f=contain&q=100",
							md:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=800&h=800&f=contain&q=100",
							lg:
								"https://sig.teemzero.com/shaping_impact/assets/inzet.png?w=1200&h=1200&f=contain&q=100",
							mime: "image/png",
						},
					},
				],
				id: Number(this.$route.params.slug),
				tempData: data,
				singleFundData: {},
				show: "null",
				singleBlocks: {
					columns: null,
					richtext: null,
					quote: null,
				},
				route_funds: globals.navigation["funds"].slug,
				btn_funds_back: globals.microcopy.btn_funds_back[globals.language],
				sections_navigation:{}
			};
		},
		mounted() {
			this.divideBlocks()

			/*
			this.$nextTick(() => {
				if (this.$route.hash) {
					let anchor_dest = this.$route.hash.split('#')
					console.log(anchor_dest)
					this.section_nav(anchor_dest[1])
				}	
			})
			*/

		},
		beforeRouteEnter(to, from, next) {
			let slug = to.params.slug;
			let json_file =
				"/json/funds/funds_" +
				globals.language +
				"__" +
				slug +
				".json?v=" +
				globals.content_version;
			fetch(json_file)
				.then((response) => response.json())
				.then(function(data) {
					globals.funds_item_cur = data
					next((vm) => vm.setData(data));
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
					next((vm) => vm.notFound());
				});
		},
		methods: {
			sections_menu(data){
				console.log('construct anchor menu')
				var mnu = {}
				const can_have_section_menu = ['list','organisations','companies']
				var menu_cnt = 0
				Object.keys(data.blocks).forEach((key) =>{
					if(can_have_section_menu.includes(data.blocks[key]['type'])){
						menu_cnt++
						mnu[menu_cnt] = {}
						mnu[menu_cnt].title = data.blocks[key]['menu_title']
						mnu[menu_cnt].link = 'section_' + data.blocks[key]['id']
					}
				})
				this.sections_navigation = mnu
			},
			section_nav(who){
				console.log('section nav: ' + who)
				/*
				console.log(this.$route)
				history.pushState(null, "", this.$route.params.slug + '#' + who)
				*/
				gsap.to(window, {duration: 1, scrollTo: '#' + who, ease:'power2.inOut'});
			},
			divideBlocks() {
				Object.keys(this.singleFundData.blocks).map(
					(key) =>
						(this.singleBlocks[
							this.singleFundData.blocks[key].type
						] = this.singleFundData.blocks[key])
				);
			},
			setData(data) {
				this.sections_menu(data)
				data.specs = JSON.parse(data.specs);
				for (let key in data.blocks) {
					if (data.blocks[key].list) {
						data.blocks[key].list = JSON.parse(data.blocks[key].list);
					}
				}
				console.log(data);

				this.show = "yes";
				this.singleFundData = data;
			},
			notFound() {
				this.show = "404";
			},
			navigate_back() {
				if (window.history.length > 3) {
					window.history.back();
				} else {
					this.$router.push({ name: "funds" });
				}
			},
		},
	};
</script>

<style>
	.circular-logo {
		display: flex;
		position: relative;
		max-width: 460px;
		max-height: 460px;
		width: 32vw;
		height: 32vw;
		overflow: hidden;
		border-radius: 50%;
		background-color: white;
		align-items: center;
		justify-content: center;
	}
	.quote-section {
		background: url("../assets/sun-2-teal.svg") no-repeat;
		background-position: right;
		background-position-x: 45vw;
		background-attachment: fixed;
	}
	@media only screen and (max-width: 640px) {
		.quote-section {
			background-position-x: 15vw;
		}
		.circular-logo {
			width: 250px;
			height: 250px;
		}
	}
</style>

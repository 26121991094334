<template>
	<div class="pt-100 pb-120 mx640:pb-16">
		<div class="mode-center">
			<div class="w-7/12 mx640:w-full mx640:mb-10 mb-120" v-if="obj.title">
				<h1 class="h1-text mx900:text-6 mb-10">
					{{obj.title}}
				</h1>
			</div>
			<div
				class="grid grid-cols-4 gap-8 mx1280:grid-cols-3 mx900:grid-cols-2 mx640:grid-cols-1"
				v-if="obj.items.length > 0"
			>
				<div class="relative" v-for="card in obj.items" :key="card.id">
					<a :href="card.link" target="_blank">
						<div class="h-230 bg-gray-100">
							<img
								v-if="card.background"
								class="h-full w-full object-cover"
								:src="card.background['md']"
								alt=""
							/>
						</div>
						<div class="bg-shea h-85">
							<div class="portfolio_logo">
								<img
									v-if="card.logo"
									class="h-full w-full object-contain"
									:src="card.logo['md']"
									alt=""
								/>
							</div>
						</div>
						<div class="bg-shea portfolio_info pb-8 px-8">
							<div class="mb-10 font-tiempos_r text-lg overflow-hidden" style="max-height:167px;" v-if="card.text" v-html="card.text">
							</div>
							<div class="text-center anime-hover" v-if="card.link">
								<a :href="card.link" target="_blank" class="font-inter text-xl mx640:4vw">
									{{visit_site}}
								</a>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "PortfolioSection",
		props: ["obj"],
		data() {
			return {
				visit_site: globals.microcopy.visit_site[globals.language],
			};
		},
	};
</script>

<style>
	.portfolio_logo {
		display: flex;
		position: relative;
		width: 130px;
		height: 130px;
		overflow: hidden;
		border-radius: 50%;
		background-color: white;
		align-items: center;
		justify-content: center;
		transform: translate(0, -65px);
		margin: 0 auto;
		z-index: 2;
	}
</style>

<template>
	<div
		class="anime-hover grid grid-cols-10 gap-4 pb-10 mb-8 border-crab border-b w-11/12 font-tiempos_r last:border-b-0 mx900:w-full items-baseline"
	>
		<div class="col-span-1">
			<div class="text-crab text-6xl font-national mx640:text-9">
				{{ index_num }}.
			</div>
		</div>
		<div
			class="col-span-3 text-30 mx1280:text-2.5 mx640:col-span-9 mx640:text-6"
		>
			{{ step.title }}
		</div>
		<div class="col-span-6 mx640:col-span-10 mx640:pt-10">
			<div
				class="mb-6 text-22 mx1280:text-2 mx640:text-4 rich-text"
				v-html="step.text"
			></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "StepList",
		props: ["step", "index"],
		data() {
			return {
				index_num: 0,
			};
		},
		mounted() {
			this.index_num = this.$props.index + 1;
		},
	};
</script>

<style></style>

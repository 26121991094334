<template>
	<div class="">
		<div class="mode-center pt-170 mx640:pt-140 pb-20">
			<div class="w-full mx640:ml-0 mx640:w-full">
				<div
					class="anime-hover grid grid-cols-5 gap-24 mx900:grid-cols-1 mx900:pb-16 mx900:gap-0"
				>
					<div class="col-span-2 mx900:pb-10">
						<img
							class="w-full"
							v-if="data_hero.image"
							:src="data_hero.image.md"
						/>
						<img
							v-if="data_hero.image == null"
							class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
							src="@/assets/logo-huge.svg"
							alt=""
						/>
					</div>
					<div class="col-span-3 text-pine">
						<h1
							class="h1-text pb-8 mx640:text-9 mx900:text-6"
							v-if="data_hero.title"
						>
							{{ data_hero.title }}
						</h1>
						<div
							class="text-24 mx1280:text-2.5 mx640:text-4.5 font-tiempos_r"
							v-if="data_hero.text"
							v-html="data_hero.text"
						></div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-microwave">
			<div
				class="mode-center grid grid-cols-2 pt-120 pb-100 gap-20 mx720:grid-cols-1 mx1080:gap-6"
			>
				<FundsCard v-for="card in fundsPageData" :key="card.id" :card="card" />

				<div class="bg-mandarin relative">
					<div
						class="anime-hover-white text-pine h-900 mx900:h-650 funds-contact-card z-10 break-words"
					>
						<div
							class="w-85% mx-auto my-0 pb-100 pt-16 h-full flex flex-col text-30 font-tiempos_r justify-between mx1080:text-4 mx640:text-6"
							v-if="data_know_more"
						>
							<h2 v-if="data_know_more.title">
								{{ data_know_more.title }}
							</h2>
							<h2 v-if="data_know_more.text" v-html="data_know_more.text"></h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import data from "@/data.json";
	import FundsCard from "@/components/ui/FundsCard";
	import { globals } from "@/modules/globals";

	export default {
		name: "Funds_v2",
		components: { FundsCard },
		data() {
			return {
				tempData: data,
				fundsPageData: data,
				data_hero: {},
				data_know_more: null,
			};
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 2;

			let fundsData = null;
			let json_file_funds =
				"/json/funds/funds_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_funds)
				.then((response) => response.json())
				.then(function(data) {
					fundsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_funds_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(fundsData, sectionsData));
				}
			}
		},
		methods: {
			setData(fundsData, sectionsData) {
				fundsData.forEach(function(item, index) {
					item.specs = JSON.parse(item.specs);
				});
				//console.log(fundsData);

				//console.log('setData')
				//console.log(data)
				this.fundsPageData = fundsData;
				this.data_hero = sectionsData["funds-intro"];
				this.data_know_more = sectionsData["want-to-know-more"];
			},
		},
	};
</script>

<style>
	.funds-contact-card {
		background: url("../assets/sun-2-pineapple.svg") no-repeat;
		background-position-y: -300px;
	}
	@media only screen and (max-width: 900px) {
		.funds-contact-card {
			background-position-y: -350px;
		}
	}
</style>
